var site = site || {};
var generic = generic || {};

(function($) {
  window.site = site || {};
  site.loyalty = site.loyalty || {};

  site.loyalty = {
    receiveMessage: function(event) {
      if (typeof event != 'undefined') {
        var offerCode = '';
        var receivedMsg = event.data;
        var socialMedium = typeof receivedMsg == 'string' ? receivedMsg.substr(receivedMsg.indexOf('|') + 1) : '';

        if (socialMedium === 'facebook') {
          offerCode = 'lyl_social_fb';
        } else if (socialMedium === 'twitter') {
          offerCode = 'lyl_social_twitter';
        }
        if (offerCode !== '') {
          site.loyalty.applyOffer(offerCode);
        }
      }
    },
    applyOffer: function(offerCode) {
      site.userInfoCookie = site.userInfoCookie || {};
      site.userInfoCookie.getValue = site.userInfoCookie.getValue || function() {
        return '';
      };

      var signedIn = parseInt(site.userInfoCookie.getValue('signed_in'));
      var isLoyaltyMember = parseInt(site.userInfoCookie.getValue('is_loyalty_member'));

      //if the user is signed in and a loyalty member apply the social media offer
      if (signedIn && isLoyaltyMember && offerCode) {
        var paramObj = {
          'offer_code': offerCode,
          'do_not_defer_messages': 1
        };
        generic.jsonrpc.fetch({
          method: 'offers.apply',
          params: [paramObj]
        });
      }
    },
    social_share: function(json) {
      if (typeof json !== 'undefined' && json.eName === 'ShoutIt') {
        var offerCode;
        if (json.socialMedium === 'Facebook') {
          offerCode = 'lyl_social_fb';
        } else if (json.socialMedium === 'Twitter') {
          offerCode = 'lyl_social_twitter';
        } else if (json.socialMedium === 'Pinterest') {
          offerCode = 'lyl_social_pinterest';
        }
        site.loyalty.applyOffer(offerCode);
      }
    },
    multiImg: function(context) {
      if ($('.loyalty_multi_image_tout_right').length) {
        var i = 0;
        var random;
        var sequence = [];
        var position = 0;
        var time_per_image = $('[data-time_per_img]', context).attr('data-time_per_img');
        while (i < $('.loyalty_multi_image_tout_right img').length) {
          random = Math.floor(Math.random() * $('.loyalty_multi_image_tout_right img', context).length);
          if (!$('.loyalty_multi_image_tout_right img', context).eq(random).hasClass('processed')) {
            $('.loyalty_multi_image_tout_right img', context).eq(random).addClass('processed');
            sequence.push(random);
            i++;
          }
        }
        site.loyaly.rotate_img(position, time_per_image, sequence);
      }
    },
    rotate_img: function(position, time_per_image, sequence) {
      position = position === sequence.length - 1 ? 0 : position + 1;
      $('.loyalty_multi_image_tout_right img').addClass('hidden');
      $('.loyalty_multi_image_tout_right img').eq(sequence[position]).removeClass('hidden');
      setTimeout(site.loyaly.rotate_img, time_per_image * 1000);
    },
    enrollmentBtn: function(context) {
      var $joinBtn = $('.js-join-popup', context);
      $joinBtn.on('click', function(event) {
        event.preventDefault();
        var signedIn = site.userInfoCookie.getValue('signed_in') - 0;
        // just submit the form
        if (signedIn) {
          if (!$('#loyalty_account_sticky__checkbox').is(':checked')) {
            $('.loyalty_market_sticky_nav .error').removeClass('hidden');
            return;
          }

          var params = {};
          params['_SUBMIT'] = 'loyalty_join';
          params['LOYALTY_ACTIVE_FLAG'] = '1';

          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function() {
              // send them to loyalty landing
              window.location.href = '/account/loyalty/index.tmpl';
            },
          });
        } else {
          Drupal.behaviors.ELB_loyalty_offer.showSignupFormNow();
        }
      });
    },
    signInBtn: function(context) {
      var $signInBtn = $('.js-sign-in-popup', context);
      $signInBtn.on('click', function(event) {
        event.preventDefault();
        // trigger sign in button or send to signin
        if ($('.device-pc').length) {
          $('.page-utilities__account-button')[0].trigger('click');
        } else {
          $.cookie('showsignin', 1);
          window.location.href = '/account/signin.tmpl';
        }
      });
    },
    checkLoyaltyPageAccess: function() {
      // a check table of products and the minimum tier needed to access the product
      // example: 36143:3 (product 36143 is restricted to tier 3 members)
      var checkTable = {};

      //product/689/36143/Product-Catalog/Skincare/By-Category/Repair-Serums/New-Dimension/Shape-Fill-Expert-Serum
      var pathArray = window.location.pathname.split('/');
      // this will return 36143
      var product = pathArray[3];

      return checkTable[product] ? checkTable[product] : 0;
    },
    loyaltyNavLinks: function() {
      window.scroll(0, 0);
      $('.account-utilities__account-details-elist a').each(function() {
        if ((window.location.hash !== '') && (this.href.indexOf(window.location.hash) > -1)) {
          $(this).addClass('active');
        } else {
          $(this).removeClass('active');
        }
      });
    }
  };

  Drupal.behaviors.ELB_loyalty = {
    attach: function() {
      if (site.features.has_loyalty_program) {
        $(window).on('hashchange', function() {
          var pageMainNode = $('.page-main');
          if (pageMainNode.hasClass('loyalty-account-page')) {
            site.loyalty.loyaltyNavLinks();
          }
        }).trigger('hashchange');

        $(document).on('click', '#form--loyalty_join--submit', function() {
          if ($('#loyalty_my_account_auth_checkbox').is(':checked')) {
            $('#clinique_gnav_loyalty_join_form').trigger('submit');
          } else {
            $('.topnav-pane .loyalty__nav .error').removeClass('hidden');
          }
        });
      }
    }
  };

  //To redeem the loyalty offers for social sharing through ModiFace tool
  window.addEventListener('message', site.loyalty.receiveMessage, false);
})(jQuery);
